import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import rootReducer from './redux/reducers/drawerReducer';
import ErrorBoundary from './components/ErrorBoundary'; 
import App from './App';
import store from './redux/store';

// const store = createStore(rootReducer);

ReactDOM.render(
  <Provider store={store}>
  <ErrorBoundary>
      <App />

  </ErrorBoundary>
  </Provider>,
  document.getElementById('root')
);
