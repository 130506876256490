import React, { useContext } from 'react';
import { Typography } from '@mui/material'; 
import { ColorContext } from 'ColorContext';

const Footer = () => {
  const { backgroundColor, textColor } = useContext(ColorContext);
  return (
    <footer style={{
      backgroundColor: backgroundColor,
      color: textColor,
      textAlign: 'center',
      padding: '0.1em 0',
      position: 'fixed',
      width: '100%',
      bottom: 0,
      left: 0
    }}>
      <Typography variant="body2">
        © {new Date().getFullYear()} Naqshabook.xyz All rights reserved.
      </Typography>
    </footer>
  );
};

export default Footer;
