import React, { useState, useEffect, useContext } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import { TextField, Grid, useMediaQuery, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Button, Stack } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setDrawerOpen } from '../../redux/actions/drawerActions';
import { fromLonLat } from 'ol/proj';
import { Drawer, Box, Typography, IconButton, MenuItem, FormControl, Select, InputLabel, Divider } from '@mui/material';
import { ColorContext } from 'ColorContext';

const Sidebar = ({ onClose, onSubmit, onReset, map, colors }) => {
  const [open, setOpen] = useState(true);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { backgroundColor, textColor } = useContext(ColorContext);

  const toggleSidebar = () => setOpen(!open);
 const isDrawerOpen = useSelector((state) => state.drawer?.isDrawerOpen);
  const accessToken = useSelector((state) => state.auth?.accessToken);
  const selectedCoordinates = useSelector( state => state.drawer?.selectedCoordinates);
  const [cities, setCities] = useState([]);
  const [towns, setTowns] = useState([]);
  const [phases, setPhases] = useState([]);
  const [blocks, setBlocks] = useState([]);
  const [lanes, setLanes] = useState([]);
  const [plots, setPlots] = useState([]);
  const [selectedPhase, setSelectedPhase] = useState('');
  const [selectedBlock, setSelectedBlock] = useState('');
  const [selectedLane, setSelectedLane] = useState('');
  const [selectedPlot, setSelectedPlot] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [selectedTown, setSelectedTown] = useState('');

  const [showLaneDropdown, setShowLaneDropdown] = useState(false);

  const dhaArr = [
    'DHA 1', 'DHA 2', 'DHA 3', 'DHA 4', 'DHA 5', 'Bahria Enclave',
    'Capital Smart City', 'New Metro City', 'Nova City', 'Taj Residentia',
    'Rudn Enclave', 'Lahore Smart City'
  ];
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [formData, setFormData] = React.useState({
 
  });
 useEffect(() => {
    fetchCities();
  }, []);

  useEffect(() => {
    if (map) {
      if (selectedCity) {
        const city = cities.find(city => city.dist_code === selectedCity);
        if (city) {
          zoomToLocation(city.x, city.y);
        }
      }
      if (selectedTown) {
        const town = towns.find(town => town.town_name === selectedTown);
        if (town) {
          zoomToLocation(town.x, town.y);
        }
      }
    }
  }, [selectedCity, selectedTown, map]);

  const fetchCities = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/get-cities/`);
      const data = await response.json();
      setCities(data);
    } catch (error) {
      toast.error('Failed to fetch cities.');
    }
  };

  const fetchTowns = async (dist_code) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/get-towns/${dist_code}/`);
      const data = await response.json();
      setTowns(data);
    } catch (error) {
      toast.error('Failed to fetch towns.');
    }
  };

  const zoomToLocation = (x, y) => {
    if (map) {
      const view = map.getView();
      view.setCenter(fromLonLat([x, y]));
      view.setZoom(12); // Adjust zoom level as needed
    }
  };

    const handleCityChange = (event) => {
      const { value } = event.target;
      setSelectedCity(value);
      setSelectedTown('');
      setSelectedPhase('');
      setSelectedBlock('');
      setSelectedLane('');
      setSelectedPlot('');
      fetchTowns(value);
    };

    const handleTownChange = async (event) => {
      const townName = event.target.value;
      setSelectedTown(townName);
      setSelectedPhase('');
      setSelectedBlock('');
      setSelectedLane('');
      setSelectedPlot('');

  if (['Capital Smart City', 'Lahore Smart City'].includes(townName)) {
    // Fetch phases if town is in specified array
    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/get-phases/${townName}/`);
        const data = await response.json();
        setPhases(data);
      } catch (error) {
        toast.error('Failed to fetch phases.');
      }
    } else {
      // Fetch blocks if town is not in specified array
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/get-blocks/${selectedCity}/${townName}/`);
        const data = await response.json();
        setBlocks(data);
      } catch (error) {
        toast.error('Failed to fetch blocks.');
      }
    }
  };


    const bahriaArray = ['Capital Smart City', 'Lahore Smart City'];
    const handlePhaseChange = async (event) => {
      const phaseId = event.target.value;
      setSelectedPhase(phaseId);
      setSelectedBlock('');
      setSelectedLane('');
      setSelectedPlot('');

      if (bahriaArray.includes(selectedTown)) {
        try {
          const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/get-blocks-by-phase/?cityId=${selectedCity}&townId=${selectedTown}&phaseId=${phaseId}`);
          const data = await response.json();
          setBlocks(data);
        } catch (error) {
          toast.error('Failed to fetch blocks.');
        }
      } else {
        // Fetch blocks as per the usual case if the town is not in bahriaArray
        try {
          const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/get-blocks/${selectedCity}/${selectedTown}/`);
          const data = await response.json();
          setBlocks(data);
        } catch (error) {
          toast.error('Failed to fetch blocks.');
        }
      }
    };


    const handleBlockChange = async (event) => {
    const blockId = event.target.value;
    setSelectedBlock(blockId);
    setSelectedLane('');
    setSelectedPlot('');
    if (selectedBlock && selectedTown) {
      if (dhaArr.includes(selectedTown)) {
        if (selectedTown === 'Capital Smart City' || selectedTown === 'Lahore Smart City') {
          const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/get-lanes-with-phase/?blockId=${selectedBlock}&townId=${selectedTown}&phaseId=${selectedPhase}&cityId=${selectedCity}`);
          const data = await response.json();
          setLanes(data);
        } else {
          const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/get-lanes/?blockId=${selectedBlock}&townId=${selectedTown}&cityId=${selectedCity}`);
          const data = await response.json();
          setLanes(data);
        }
      } else {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/get-plots/?blockId=${selectedBlock}&townId=${selectedTown}&cityId=${selectedCity}`);
        const data = await response.json();
        setPlots(data);
      }
    }
  };

   const handleLaneChange = async (event) => {
    const laneId = event.target.value;
    setSelectedLane(laneId);

    if (selectedBlock && selectedTown) {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/get-plots-with-lanes/?blockId=${selectedBlock}&townId=${selectedTown}&laneId=${laneId}&cityId=${selectedCity}`);
      const data = await response.json();
      setPlots(data);
    }
  };

    const handlePlotChange = (event) => {
    setSelectedPlot(event.target.value);

    console.log('selectedPlot',selectedPlot);
    const plotCoordinates = event.target.value.split(' ');
    const latitude = parseFloat(plotCoordinates[1]);
    const longitude = parseFloat(plotCoordinates[0]);


    if (latitude && longitude) {
        const view = map?.getView();
        const coordinates = fromLonLat([longitude, latitude]); 

        view.animate({
            center: coordinates,
            zoom: 17, 
            duration: 1000 
        });
    }
};


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    let isValid = true;
    let tempErrors = {};
    return { isValid, tempErrors };
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { isValid, tempErrors } = validateForm();
    if (!isValid) {
      for (const [key, error] of Object.entries(tempErrors)) {
        toast.error(error);
      }
      return;
    }
    const dataToSend = { ...formData };
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/create-reserve/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(dataToSend),
      });
      const body = await response.json();
      if (response.status >= 200 && response.status < 300) {
        toast.success('Form submitted successfully!');
        onReset();
        onClose();
      } else if (response.status === 401) {
        toast.error('Session expired. Please log in again.');
        navigate('/sign-in');
      } else {
        toast.error(body.treaty_number ? body.treaty_number.join(' ') : 'Form submission failed!');
      }
    } catch (error) {
      toast.error('Form submission failed!');
      console.error('Error:', error);
    }
  };
  

  const handleReset = () => {
    setFormData({

    });
    onReset();
    onClose();
  };

  const closeDrawer = () => {
    dispatch(setDrawerOpen(false))
}

  return (
    <>
      <IconButton
        onClick={toggleSidebar}
        style={{
          position: 'fixed',
          top: 20,
          right: 10,
          zIndex: 1300,
        }}
      >
        <MenuIcon />
      </IconButton>
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          right: 0,
          height: '100%',
          width: open ? (isMobile ? '50%' : '25%') : '0',
          backgroundColor: backgroundColor || '#1D2D37',
          overflowX: 'hidden',
          transition: '0.3s',
          zIndex: 1200,
          padding: '10px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <Box p={2} width="100%" style={{ marginTop: '20%' }}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="h6" style={{ color: textColor || '#fff' }}>
                Search Plots
              </Typography>
            </Box>
            <Divider style={{ backgroundColor: textColor || '#fff', margin: '10px 0' }} />

            <form onSubmit={handleSubmit}>
              {/* Select City */}
              <FormControl fullWidth margin="normal">
                <InputLabel style={{ color: textColor || '#fff' }}>Select City</InputLabel>
                <Select
                  name="city"
                  variant="outlined"
                  label="Select City"
                  style={{ color: textColor || '#fff', borderColor: '#fff' }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        backgroundColor: backgroundColor || '#1D2D37',
                        color: textColor || '#fff'
                      }
                    }
                  }}
                  value={selectedCity}
                  onChange={handleCityChange}
                >
                  {cities.map(city => (
                    <MenuItem key={city.dist_code} value={city.dist_code}>
                      {city.district}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {/* Select Town */}
              <FormControl fullWidth margin="normal">
                <InputLabel style={{ color: textColor || '#fff' }}>Select Town</InputLabel>
                <Select
                  name="town"
                  variant="outlined"
                  label="Select Town"
                  style={{ color: textColor || '#fff', borderColor: '#fff' }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        backgroundColor: backgroundColor || '#1D2D37',
                        color: textColor || '#fff'
                      }
                    }
                  }}
                  value={selectedTown}
                  onChange={handleTownChange}
                >
                  {towns.map(town => (
                    <MenuItem key={town.town_name} value={town.town_name}>
                      {town.town_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {/* Select Phase */}
              <FormControl fullWidth margin="normal">
                <InputLabel style={{ color: textColor || '#fff' }}>Select Phase</InputLabel>
                <Select
                  name="phase"
                  variant="outlined"
                  label="Select Phase"
                  style={{ color: textColor || '#fff', borderColor: '#fff' }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        backgroundColor: backgroundColor || '#1D2D37',
                        color: textColor || '#fff'
                      }
                    }
                  }}
                  value={selectedPhase}
                  onChange={handlePhaseChange}
                >
                  {phases.map(phase => (
                    <MenuItem key={phase.phase_id} value={phase.phase_id}>
                      {phase.phase_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {/* Select Block/Sector */}
              <FormControl fullWidth margin="normal">
                <InputLabel style={{ color: textColor || '#fff' }}>Select Block/Sector</InputLabel>
                <Select
                  name="block"
                  variant="outlined"
                  label="Select Block/Sector"
                  style={{ color: textColor || '#fff', borderColor: '#fff' }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        backgroundColor: backgroundColor || '#1D2D37',
                        color: textColor || '#fff'
                      }
                    }
                  }}
                  value={selectedBlock}
                  onChange={handleBlockChange}
                >
                  {blocks.map(block => (
                    <MenuItem key={block.block_id} value={block.block_id}>
                      {block.block_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {/* Select Lane */}
              {lanes.length > 0 && (
                <FormControl fullWidth margin="normal">
                  <InputLabel style={{ color: textColor || '#fff' }}>Select Lane</InputLabel>
                  <Select
                    name="lane"
                    variant="outlined"
                    label="Select Lane"
                    style={{ color: textColor || '#fff', borderColor: '#fff' }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          backgroundColor: backgroundColor || '#1D2D37',
                          color: textColor || '#fff'
                        }
                      }
                    }}
                    value={selectedLane}
                    onChange={handleLaneChange}
                  >
                    {lanes.map(lane => (
                      <MenuItem key={lane.id} value={lane.name}>
                        {lane.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}

              {/* Select Plot */}
              {plots.length > 0 && (
                <FormControl fullWidth margin="normal">
                  <InputLabel style={{ color: textColor || '#fff' }}>Select Plot</InputLabel>
                  <Select
                    name="plot"
                    variant="outlined"
                    label="Select Plot"
                    style={{ color: textColor || '#fff', borderColor: '#fff' }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          backgroundColor: backgroundColor || '#1D2D37',
                          color: textColor || '#fff'
                        }
                      }
                    }}
                    value={selectedPlot}
                    onChange={handlePlotChange}
                  >
                    {plots.map(plot => (
                      <MenuItem key={plot.plot_id} value={plot.plot_id}>
                        {plot.plot_no}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}

              <Box mt={2} display="flex" justifyContent="space-between">
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  style={{
                    backgroundColor: textColor || '#fff',
                    color: backgroundColor || '#1D2D37',
                    textTransform: 'none',
                    marginRight: '10px',
                  }}
                >
                  Submit
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleReset}
                  style={{
                    borderColor: textColor || '#fff',
                    color: textColor || '#fff',
                    textTransform: 'none',
                  }}
                >
                  Reset
                </Button>
              </Box>
            </form>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Sidebar;

