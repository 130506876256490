import { combineReducers } from "redux";
import authReducer from "./authReducer";
import drawerReducer from "./drawerReducer";


// combine all the reducers here
export const rootReducer = combineReducers({
    auth: authReducer,
    drawer: drawerReducer,
})

export default rootReducer;