import { SET_DRAWER_OPEN, SET_SELECTED_COORDINATES } from '../actions/drawerActions';

const initialState = {
  isDrawerOpen: false,
  selectedCoordinates: null,
};

const drawerReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DRAWER_OPEN:
      return {
        ...state,
        isDrawerOpen: action.payload,
      };
    case SET_SELECTED_COORDINATES:
      return {
        ...state,
        selectedCoordinates: action.payload,
      };
    default:
      return state;
  }
};

export default drawerReducer;
