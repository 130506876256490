import React, { useState } from 'react';
import axios from 'axios';
import { TextField, Button, Box, Typography, Container } from '@mui/material';
import { Navigate, useNavigate } from 'react-router-dom'; 
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../redux/actions/authActions';

const SignInForm = () => {
  const navigate = useNavigate(); 
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    username: '',
    password: '',
  });
  const loading = useSelector((state) => state.auth?.loading)
  const isAuthenticated = useSelector((state) => state.auth?.isAuthenticated);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const res = await dispatch(login(formData))
    console.log("🚀 ~ $$$ ~ handleSubmit ~ res:", res)
    if(res?.data?.access){
      navigate('/');
    }
  };

  if (isAuthenticated){
    return <Navigate to="/" />
  }
    return (
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography component="h1" variant="h5">
            Sign In
          </Typography>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoComplete="username"
              autoFocus
              value={formData.username}
              onChange={handleChange}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={formData.password}
              onChange={handleChange}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={loading}
            >
              {loading ? "loading..." : "Sign In"}
            </Button>
          </Box>
        </Box>
      </Container>
    );
};

export default SignInForm;
