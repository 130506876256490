import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Container, Typography, Paper } from '@mui/material';

const DataTable = () => {
  const [reserves, setReserves] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchReserves = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/list_reserves/`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setReserves(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchReserves();
  }, []);

  if (loading) return <Typography>Loading...</Typography>;
  if (error) return <Typography>Error: {error}</Typography>;

  const columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    { field: 'name', headerName: 'Name', width: 150 },
    { field: 'email', headerName: 'Email', width: 200 },
    { field: 'phone', headerName: 'Phone', width: 150 },
    { field: 'type', headerName: 'Type', width: 120 },
    { field: 'treaty_number', headerName: 'Treaty Number', width: 180 },
    { field: 'duration', headerName: 'Duration', width: 120 },
    { field: 'date', headerName: 'Date', width: 150 },
    { field: 'habitat', headerName: 'Habitat', width: 150 },
    { field: 'observation', headerName: 'Observation', width: 200 },
    { field: 'species', headerName: 'Species', width: 150 },
    { field: 'quantity', headerName: 'Quantity', width: 120 },
    { field: 'condition', headerName: 'Condition', width: 120 },
    { field: 'latitude', headerName: 'Latitude', width: 150 },
    { field: 'longitude', headerName: 'Longitude', width: 150 },
  ];

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Reserve Data
      </Typography>
      <Paper style={{ height: 600, width: '100%' }}>
        <DataGrid
          rows={reserves}
          columns={columns}
          pageSize={10}
          loading={loading}
          rowsPerPageOptions={[10]}
          disableSelectionOnClick
        />
      </Paper>
    </Container>
  );
};

export default DataTable;
