import axios from "axios";
import { AUTHENTICATION_FAILED, AUTHENTICATION_STARTED, IS_AUTHENTICATED, LOGOUT } from "../../utils/constants";

export const login = (formData) => async (dispatch) => {
  dispatch({type: AUTHENTICATION_STARTED})
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/accounts/sign-in/`,
      formData
    )
    const accessToken  = response?.data?.access;
    if(!accessToken) {
      return dispatch({ type: AUTHENTICATION_FAILED });
    }
    localStorage.setItem("authToken", accessToken);
    dispatch(setToken(accessToken));
    return response
  }catch(e) {
    dispatch({ type: AUTHENTICATION_FAILED });
    alert("Sign-in failed");
    return null;
  }
};

export const setToken = (token) => {
  return { type: IS_AUTHENTICATED, payload: token }
}

// Call this function when we need to clear the redux state, e.g when we want to logout from the application
export const logout = () => (dispatch) => {
  localStorage.removeItem("authToken")
  return dispatch({type: LOGOUT, payload: 'LOGOUT'});
};