export const SET_DRAWER_OPEN = 'SET_DRAWER_OPEN';
export const SET_SELECTED_COORDINATES = 'SET_SELECTED_COORDINATES';

export const setDrawerOpen = (isOpen) => ({
  type: SET_DRAWER_OPEN,
  payload: isOpen,
});

export const setSelectedCoordinates = (coordinates) => ({
  type: SET_SELECTED_COORDINATES,
  payload: coordinates,
});
