import { AUTHENTICATION_FAILED, AUTHENTICATION_STARTED, IS_AUTHENTICATED } from "../../utils/constants";

const initialState = {
  isAuthenticated: false,
  accessToken: "",
  loading: false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case IS_AUTHENTICATED:
      const payload = action?.payload;
      if(!payload){
        return { ...state, loading:false }
      }
      return {
        ...state,
        isAuthenticated: !!action?.payload,
        accessToken: action?.payload,
        loading: false,
      };
    case AUTHENTICATION_STARTED:
      return {
        ...state,
        loading: true,
      };
    case AUTHENTICATION_FAILED:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};


export default authReducer;
