import React, { useContext } from 'react';
import { Home, Search, Info } from '@mui/icons-material';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import MapIcon from '@mui/icons-material/Map';
import RateReviewIcon from '@mui/icons-material/RateReview';
import CalendarViewDayIcon from '@mui/icons-material/CalendarViewDay';
import { IconButton, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { ColorContext } from 'ColorContext';

const FloatingToolbar = ({ onBaseMapChange }) => {
   const { backgroundColor, textColor } = useContext(ColorContext);


    return (
    <div style={{
      position: 'fixed',
      top: '70px',
      right: '35%',
      backgroundColor: backgroundColor || 'rgba(29, 45, 55, 0.8)',
      padding: '10px',
      borderRadius: '8px',
      display: 'flex',
      alignItems: 'center',
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)'
    }}>
      <Tooltip title="Home">
        <IconButton style={{ color: textColor || '#fff' }}>
          <Home />
        </IconButton>
      </Tooltip>
      <Tooltip title="Full Screen map">
        <IconButton style={{ color: textColor || '#fff' }}>
          <FullscreenIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Print Map">
        <IconButton style={{ color: textColor || '#fff' }}>
          <LocalPrintshopIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="3D Map View">
        <IconButton style={{ color: textColor || '#fff' }}>
          <MapIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="View Feedback">
        <IconButton style={{ color: textColor || '#fff' }}>
          <RateReviewIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Add Feedback">
        <IconButton style={{ color: textColor || '#fff' }}>
          <CalendarViewDayIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Change basemap">
        <FormControl style={{ marginLeft: '10px', minWidth: '120px' }}>
          <Select
            onChange={(e) => onBaseMapChange(e.target.value)}
            defaultValue="mapbox"
            style={{ color: textColor || '#fff', backgroundColor: backgroundColor || '#1D2D37', borderColor: '#fff' }}
          >
            <MenuItem value="osm"  style={{ color: textColor || '#fff', backgroundColor: backgroundColor || '#1D2D37', borderColor: '#fff' }}
                >Open Street Map</MenuItem>
            <MenuItem style={{ color: textColor || '#fff', backgroundColor: backgroundColor || '#1D2D37', borderColor: '#fff' }}
                value="bing">Bing Maps</MenuItem>
            <MenuItem style={{ color: textColor || '#fff', backgroundColor: backgroundColor || '#1D2D37', borderColor: '#fff' }}
                value="mapbox">Night View</MenuItem>
          </Select>
        </FormControl>
      </Tooltip>
    </div>
  );
};

export default FloatingToolbar;