
import {
  applyMiddleware,
  legacy_createStore as createStore,
  compose,
} from "redux";
import rootReducer from './reducers/rootReducer';
import {thunk} from "redux-thunk";
import { LOGOUT } from "../utils/constants";

const appReducer = (state, action) => {
    if(action.type === LOGOUT){
      return rootReducer(undefined, action);
    }
    return rootReducer(state, action);
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;


const store = createStore(appReducer, composeEnhancers(applyMiddleware(thunk)));

export default store;
