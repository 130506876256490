// import React, { createContext, useState } from 'react';

// export const ThemeContext = createContext();

// export const ThemeProvider = ({ children }) => {
//   const [theme, setTheme] = useState({
//     backgroundColor: '#1D2D37',
//     textColor: '#ffffff',
//   });

//   const updateTheme = (newTheme) => {
//     setTheme((prevTheme) => ({
//       ...prevTheme,
//       ...newTheme,
//     }));
//   };

//   return (
//     <ThemeContext.Provider value={{ theme, updateTheme }}>
//       {children}
//     </ThemeContext.Provider>
//   );
// };

import React, { createContext, useState } from 'react';

// Create the context
export const ColorContext = createContext();

export const ColorProvider = ({ children }) => {
  // Define default colors
  const [backgroundColor, setBackgroundColor] = useState('#1D2D37');
  const [textColor, setTextColor] = useState('#ffffff');

  const value = {
    backgroundColor,
    textColor,
    setBackgroundColor,
    setTextColor,
  };

  return (
    <ColorContext.Provider value={value}>
      {children}
    </ColorContext.Provider>
  );
};
