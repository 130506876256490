import React, { useEffect, useRef, useState, useContext } from 'react'
import { Dialog, DialogTitle, DialogContent } from "@mui/material";
import DataTable from '../components/Common/DataTable';
import { Card } from 'react-bootstrap';
import SearchComponent from '../components/Search/Search';
import DrawerComponent from '../components/Drawer/Drawer';
import Sidebar from '../components/Drawer/Sidebar';
import { useDispatch, useSelector } from 'react-redux';
import { Draw, Modify, Snap } from "ol/interaction";
import TileLayer from 'ol/layer/Tile';
import { OSM } from 'ol/source';
import VectorLayer from 'ol/layer/Vector';
import { Map, Feature, View } from "ol";
import { fromLonLat, transform } from 'ol/proj';
import { setDrawerOpen, setSelectedCoordinates } from '../redux/actions/drawerActions';
import VectorSource from 'ol/source/Vector';
import { Point } from 'ol/geom';
import { notify } from '../utils/notify';
import Toolbar from '../components/Toolbar/Toolbar';
import Footer from './Footer';
import FloatingToolbar from './FloatingToolbar';
import BingMaps from 'ol/source/BingMaps';
import XYZ from 'ol/source/XYZ';
import { ColorContext } from 'ColorContext';


const vectorSource = new VectorSource();

const Home = () => {
  const mapRef = useRef(null);
  console.log("🚀 ~ $$$ ~ Home ~ mapRef:", mapRef)
  const dispatch = useDispatch();
  const [mapInitialized, setMapInitialized] = useState(false);
  const { backgroundColor, textColor } = useContext(ColorContext);

  const isAuthenticated = useSelector(state => state?.auth?.isAuthenticated)
  const [baseMapSource, setBaseMapSource] = useState(
    new XYZ({
      url: 'https://api.mapbox.com/styles/v1/mapbox/dark-v10/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiZ2lzdGVjaCIsImEiOiJjajdxMXdvb3YzY2hiMzN0NWhjb2VkYWNlIn0.bkcli-wKDsl3wCDOdO27rA'
    })
  ); // dark-v10


 const handleBaseMapChange = (mapType) => {
  let newBaseMapSource;

  switch (mapType) {
    case 'osm':
      newBaseMapSource = new OSM();
      break;
    case 'bing':
      newBaseMapSource = new BingMaps({
        key: 'Your-Bing-Maps-API-Key',
        imagerySet: 'Aerial'
      });
      break;
    case 'mapbox':
      newBaseMapSource = new XYZ({
        url: 'https://api.mapbox.com/styles/v1/mapbox/dark-v10/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiZ2lzdGVjaCIsImEiOiJjajdxMXdvb3YzY2hiMzN0NWhjb2VkYWNlIn0.bkcli-wKDsl3wCDOdO27rA'
      });
      break;
    default:
      newBaseMapSource = new OSM();
  }

  if (mapRef.current) {
    const baseLayer = mapRef.current.getLayers().getArray().find(layer => layer instanceof TileLayer);
    if (baseLayer) {
      baseLayer.setSource(newBaseMapSource);
    }
  }
};

  useEffect(() => {
    if ( !mapInitialized) { // isAuthenticated &&

      const initialMap = new Map({
        target: 'map',
        layers: [
          new TileLayer({ source: baseMapSource }),
          new VectorLayer({
            source: vectorSource,
          }),
        ],
        view: new View({
          center: fromLonLat([73.0363, 33.738045]),
          zoom: 8,
        }),
      });

      const draw = new Draw({
        source: vectorSource,
        type: 'Point',
      });

      initialMap.addInteraction(draw);
      initialMap.addInteraction(new Modify({ source: vectorSource }));
      initialMap.addInteraction(new Snap({ source: vectorSource }));

      const handleClick = (event) => {
        console.log('Map clicked');
        const coords = event.coordinate;
        const transformedCoords = transform(coords, 'EPSG:3857', 'EPSG:4326');
        const feature = new Feature({
          geometry: new Point(coords),
        });

        vectorSource.addFeature(feature);
        dispatch(setSelectedCoordinates(transformedCoords));
        dispatch(setDrawerOpen(true));

      };

      initialMap.on('click', handleClick);

      mapRef.current = initialMap;
      setMapInitialized(true);
    }
  }, [baseMapSource, isAuthenticated, mapInitialized, vectorSource, dispatch]);

  const handleSearch = (event) => {
    const coordinates = event;
    if (mapRef.current && coordinates && coordinates.length === 2) {
      const [lon, lat] = coordinates;
      const center = fromLonLat([lon, lat]);
      const view = mapRef.current.getView();

      view.setCenter(center);
      view.setZoom(12);

      setTimeout(() => {
        mapRef.current.renderSync();
        mapRef.current.updateSize();
      }, 500);
    } else {
      console.error(
        "Map or coordinates are not available or invalid:",
        mapRef.current,
        coordinates
      );
    }
  };

  const handleSubmit = (formData) => {

  }

  const handleReset = () => {
    vectorSource.clear();
    dispatch(setSelectedCoordinates(null));
    dispatch(setDrawerOpen(false));
  };

 return (
  <>
   <Toolbar />
      <div id="map" className="map" style={{ backgroundColor: backgroundColor }}></div>
      <Dialog open="" onClose="" maxWidth="lg" fullWidth>
        <DialogTitle style={{ color: textColor }}>Data Table</DialogTitle>
        <DialogContent style={{ color: textColor }}>
          <DataTable />
        </DialogContent>
      </Dialog>

      <Card style={{ width: "18rem", top: "5em", left: "0.5em", backgroundColor: backgroundColor, color: textColor }}>
        <Card.Body>
          <Card.Title></Card.Title>
          <SearchComponent onSearch={handleSearch} />
        </Card.Body>
      </Card>
    {/* <DrawerComponent
      onClose={handleReset}
      onSubmit={handleSubmit}
      onReset={handleReset}
      map={mapRef.current}
      // hideBackdrop={true}
      sx={{ top: '4.5em' }} 
    /> */}
      <Sidebar     
      onClose={handleReset}
      onSubmit={handleSubmit}
      onReset={handleReset}
      map={mapRef.current}
      />
    <FloatingToolbar onBaseMapChange={handleBaseMapChange} />
    <Footer />
  </>
);
}

export default Home